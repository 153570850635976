/* eslint-disable */
export default function(key = null) {
  const url = `/api/config`;

  const {data: cached} = useNuxtData("fetch_config")
  if(cached.value) {
    return {data: cached}
  }
  
  return useAsyncData(`fetch_config`, () => $fetch(url));
}